import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { tokenNotExpired } from "angular2-jwt";
import { PublicModule } from "../public.module";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public authToken: any;
  public user: any;
  public headers: Headers;
  public baseUrl: String = "https://medicinauniandes-bitacoras.imagineapps.co/";
  //public baseUrl: String = "http://localhost:8080/";

  constructor(public http: Http) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.user = JSON.parse(localStorage.getItem("usuario")) || {};
  }

  getAux({ api }: { api: String }) {
    return this.http
      .get(`${this.baseUrl}${api}`, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  public authenticateUser(user) {
    return this.http
      .post(this.baseUrl + "auth/login", JSON.stringify(user), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }

  public storeUserData(token, user) {
    localStorage.setItem("id_token", token);
    localStorage.setItem("usuario", JSON.stringify(user));
    this.authToken = token;
    this.user = user;
  }

  public forgot(email) {
    const obj = { email };
    return this.http
      .post(this.baseUrl + "users/forgot", JSON.stringify(obj), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }

  public change(email, password, passwordNew) {
    const obj = { email, password, passwordNew };
    return this.http
      .post(this.baseUrl + "users/reset", JSON.stringify(obj), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }

  public loadToken() {
    const token = localStorage.getItem("id_token");

    this.authToken = token;
  }
  public logout() {
    this.authToken = null;
    this.user = {};
    localStorage.clear();
  }
  public getToken() {
    this.loadToken();
    return this.authToken;
  }
  public loggedIn() {
    return tokenNotExpired("id_token");
  }
  public getUser() {
    return this.user;
  }
}
